import React, {useState} from 'react'

import Close from '../assets/svgs/close.svg'
import ClientshotLogo from '../assets/svgs/logo/Clientshot_logo.svg'

import Waitlist from './modals/Waitlist'
import { Link } from 'react-router-dom'

const Sidebar = ({ display, toggleSidebar, num }) => {

    const [modal, setModal] = useState(false);

    const closeModal = () => setModal(!modal) //close modal
    
  return (
    <> <Waitlist modal={modal} closeModal={closeModal} />
        {num === 0 ? <></> : (

        <div  className={display ? "overlay w-screen h-screen z-[1000] top-0 fixed bg-black bg-opacity-50 animate__animated animate__fadeIn animate__faster" 
        : "overlay w-screen h-screen z-[1000] hidden top-0 fixed bg-black bg-opacity-50 animate__animated animate__fadeOut animate__faster"}>
            <div className={display? 'bg-white h-screen w-4/5  animate__animated animate__fadeInLeft animate__faster'
            : 'bg-white h-screen w-4/5 animate__animated animate__fadeOutLeft animate__faster'}>
                
                <div className="flex flex-col justify-between h-screen">
                    <div>
                        {/* logo and close button */}
                        <div className="flex justify-between items-center p-6">
                            <Link to="#">
                                <img src={ClientshotLogo} style={{height : 30}} alt="" />
                            </Link>


                            <button onClick={() => toggleSidebar()}>
                                <img src={Close} alt="" />
                            </button>
                        </div>

                        <div className="pl-6 pr-6 mt-6">
                            <Link to={`/`} onClick={() => toggleSidebar()} className='text-link block mb-6'>
                                Home
                            </Link>

                            <Link to={`/pricing`} onClick={() => toggleSidebar()} className='text-link block mb-6'>
                                Pricing
                            </Link>

                            <Link to={`/about`} onClick={() => toggleSidebar()} className='text-link block mb-6'>
                                About
                            </Link>

                            <Link to={`/contact`} onClick={() => toggleSidebar()} className='text-link block mb-6'>
                                Contact
                            </Link>

                            <a href='https://clientshot.com/blog' onClick={() => toggleSidebar()} className='text-link block mb-6'>
                                Blog
                            </a>

                            <div className='w-full flex'>
                                <a href='https://app.clientshot.com/' target={'_blank'} rel={'noreferrer'} className='btn btn-primary w-full text-center'>
                                    Start free trial
                                </a>
                            </div>
                        </div>    
                    </div>

                    <footer className='text-center'>
                        <p className='small-text'>&copy; Copyright 2023 Clientshot</p>

                        <p className='small-text'>
                            A product of <a href={"https://seamhealth.com"} target={"_blank"} rel="noreferrer"  className='primary-500'>SeamHealth Group</a> 
                        </p>
                        
                        <div className="flex mt-8 justify-around items-center">
                            <Link to="#" onClick={() => toggleSidebar()} className='text-link small-text'>
                                Terms of Service
                            </Link>

                            <Link to="#" onClick={() => toggleSidebar()} className='text-link small-text'>
                                Privacy Policy
                            </Link>

                            <Link to="#" onClick={() => toggleSidebar()} className='text-link small-text'>
                                Sitemap
                            </Link>
                        </div>   
                    </footer>
                </div>
            </div>
        </div>
         )}  
    </>
    
  )
}

export default Sidebar