import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/fontawesome-free-solid'

const Accordion = ({ title, desc }) => {

    const [toggle, setToggle] = React.useState(false);

  return (
    <>
        <div className='accordion p-3 md:p-7'>
            <button className='flex justify-between px-0 w-full' onClick={() => setToggle(!toggle)}> 
                <p className={!toggle ? 'base-text-m text-left' : 'base-text-m text-left primary-500'}>
                    {title}
                </p>

                <FontAwesomeIcon icon={faChevronDown} className={!toggle ? '' : 'expand'} style={{fontSize : 14}} />
                
            </button>

            {/* content */}

            <div className={toggle ? 'mt-8' : 'mt-8 hidden'}>
                {desc}
            </div>
        </div>
        
    </>
  )
}

export default Accordion