import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import './CompanyPageLoader.css'
import axios from 'axios';

const CompanyLoaderPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pathName, setPathname] = useState('')
  useEffect(() => {
    const path = location.pathname
    const urlName = path.slice(1, path.length)
    setPathname(urlName);
    checkIfCompanyExist(urlName)
  })
  const checkIfCompanyExist = async (pathName) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}tiny-url/${pathName}`)
      const companyName = response.data.data.originalCompanyName.replace(/\s+/g, "-");
      window.location.replace(`${process.env.REACT_APP_COMPANY_WEB_CHANNEL}/${companyName}`, { replace: true })
    } catch (error) {
      navigate('/error/error')
    }
  }
  React.useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page
  return (
    <>
      <div className="loadWrapper">
        <div className="loader loader1">
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>Loading, Please wait!</div>
      </div >

    </>
  )
}

export default CompanyLoaderPage