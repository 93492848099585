import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { LocationContext } from '../components/contexts/LocationContext';
import Menu from '../assets/svgs/menu.svg'
import Sidebar from '../components/Sidebar'
import usdflag from '../assets/svgs/usd-flag.svg'
import ngnflag from '../assets/svgs/ngn-flag.svg'
import ChevronDown from '../assets/svgs/chevron-down.svg'
import ChevronDownLight from '../assets/svgs/chevron-down+light.svg'
import CheckIcon from '../assets/svgs/check-icon-alt.svg'
import Waitlist from '../components/modals/Waitlist'
import ClientshotLogo from '../assets/svgs/logo/Clientshot_logo.svg'
import ClientshotIconLogo from '../assets/svgs/logo/Clientshot_icon_logo.svg'
import ClientshotLogoWhite from '../assets/svgs/Clientshot_logo_white.svg'

const Header = () => {
    const [toggle, setToggle] = useState(false);
    const [num, setNum] = useState(0);
    const [modal, setModal] = useState(false);
    const [scrollPosition, setscrollPosition] = useState(0);
    const closeSidebar = () => setToggle(!toggle) // close sidebar
    const closeModal = () => setModal(!modal) //close modal
    const [toggleCurrency, setToggleCurrency] = useState(false)
    
    // check what page  user is on to determine what topbar to use
    let pathname = window.location.pathname;

    const {currency, setCurrency, setManualSwitch} = useContext(LocationContext)
    
    window.addEventListener('scroll', () =>{
        const position = window.scrollY
        setscrollPosition(position)
        // console.log(scrollPosition);
      })
    
  return (
    <>
    
        <nav className={(scrollPosition < 65 && pathname === '/') ? "top-bar bg-white mx-auto md:bg-transparent px-2 top-0 fixed w-full z-1000 md:px-8 flex text-white justify-between drop-shadow"
        : (pathname !== '/') ? "top-bar bg-white mx-auto px-2 fixed top-0 w-full z-1000 md:px-8 flex justify-between outline-bottom"
        : "top-bar bg-white mx-auto px-2 fixed top-0 w-full z-1000 md:px-8 flex justify-between outline-bottom"}>
            <div className="flex gap-10 items-center">
                <div className='flex gap-2'>
                    <button onClick={() => {setToggle(true); setNum(1) }} className='top-bar-menu' alt="menu" aria-label="menu">
                        <img src={Menu} alt="" />
                    </button>
                    <Link to={`/`} className='hidden md:flex'>
                        <img src={scrollPosition >= 65 ? ClientshotLogo : pathname !== "/" ? ClientshotLogo : ClientshotLogoWhite} style={{height : 40}} alt="" />
                    </Link>
                    <Link to={`/`} className='md:hidden' aria-label="Logo" alt="logo">
                        <img src={ClientshotIconLogo} style={{height : 40}} alt="" />
                    </Link>
                    
                </div>
                <div className="md:flex gap-8 items-center hidden top-bar-links">
                    <Link to={'/'} className='text-link base-text-m'>
                        Home
                    </Link>

                    <Link to={`/pricing`} className='text-link base-text-m'>
                        Pricing
                    </Link>

                    <Link to={`/about`} className='text-link base-text-m'>
                        About
                    </Link>

                    <Link to={`/contact`} className='text-link base-text-m'>
                        Contact
                    </Link>
                    <a href='https://clientshot.com/blog' 
                    //</div>target={'_blank'} rel={'noreferrer'} 
                    className='text-link base-text-m '>
                    <p className="m-0 secondary-30">Blog</p>
                </a>

                </div>    
            </div>

            {/* <button className='btn btn-primary' onClick={() => setModal(true)}>
                Join Waitlist
            </button> */}

            <div className="flex items-center gap-4">

            <div>
                {/* currency switcher toggle */}
                <span className="flex gap-x-2 flag-toggle flat items-center gray-hover" onClick={() => setToggleCurrency(true)}>
                        <div className='flex gap-x-2 items-center'>
                            <img src={currency === 0 ? ngnflag : usdflag} draggable={false} style={{ height: 24 }} alt="" />
                            {currency === 0 ? 'NGN' : 'USD'}
                        </div>
                        <img src={scrollPosition < 65 ? ChevronDownLight : ChevronDown} style={{ height: 16 }} draggable={false} alt="" />
                    </span>

                    {/* dropdown list */}
                    {toggleCurrency && <div className="dropdown-list">
                                    <div className="option flex gap-x-2 justify-between items-center" onClick={() => { setToggleCurrency(false); setCurrency(0); setManualSwitch(true) }}>
                                        <div className='flex gap-x-2'>
                                            <img src={ngnflag} draggable={false} style={{ height: 24 }} alt="" />
                                            NGN
                                        </div>
                                        {currency === 0 && <img src={CheckIcon} alt="" />}
                                    </div>
                                    <div className="option gap-x-2 items-center justify-between" onClick={() => { setToggleCurrency(false); setCurrency(1); setManualSwitch(true) }}>
                                        <div className='flex gap-x-2'>
                                            <img src={usdflag} draggable={false} style={{ height: 24 }} alt="" />
                                            USD
                                        </div>
                                        {currency === 1 && <img src={CheckIcon} alt="" />}
                                    </div>
                                </div>}

                                {toggleCurrency && <div className="dropdown-overlay" onClick={() => setToggleCurrency(false)}>
                                    {/* Dropdown overlay */}
                                </div>}
            </div>
                
                <a href='https://app.clientshot.com/signin' target={'_blank'} rel={'noreferrer'} id='signin' className={scrollPosition >= 65 ? 'btn btn-secondary' : pathname !== "/" ? 'btn btn-secondary' : 'btn btn-alt'}>
                    <p className="m-0 secondary-30">Sign in</p>
                </a>
                <a href='https://app.clientshot.com/' target={'_blank'} rel={'noreferrer'} id='freetrial' className='btn btn-primary gap-2 w-max '>
                    Start free trial
                </a>
            </div>

        </nav>

        <Sidebar display={toggle} num={num} toggleSidebar={closeSidebar} />
        <Waitlist modal={modal} closeModal={closeModal} />
    {/* </div> */}
    </>
  )
}

export default Header